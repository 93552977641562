<template>
    <v-container fluid class="text-center custom-background pa-16">
      <v-row justify="center">
        <v-col cols="12">
          <h1 class="animated-title white--text">
            Your Dream Website or App in Just a Few Steps
          </h1>
        </v-col>
      </v-row>
  
      <v-row justify="center" class="mt-10">
        <v-col
          v-for="(step, index) in steps"
          :key="index"
          cols="10"
          sm="6"
          md="4"
          class="d-flex justify-center"
        >
          <v-card
            :class="['custom-card', { hovered: hoverStates[index] }]"
            elevation="4"
          >
            <v-card-title class="headline font-weight-bold d-flex justify-center align-center">
              <v-icon class="icon mr-3" :color="step.iconColor">
                {{ step.icon }}
              </v-icon>
              {{ step.title }}
            </v-card-title>
            <v-card-text class="text-dark">
              <p class="description">{{ step.description }}</p>
              <div v-if="step.expanded">
                <ul>
                  <li v-for="(item, idx) in step.items" :key="idx">
                    {{ item }}
                  </li>
                </ul>
              </div>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn color="black" dark @click="toggleExpand(index)">
                {{ step.expanded ? 'Show Less' : 'Read More' }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: "DreamWebsiteAppSteps",
    data() {
      return {
        steps: [
          {
            title: "Concept",
            description: "Building a foundation for your website or app starts here.",
            items: [
              "Reviewing any existing branding",
              "Understanding target audience and competitors",
              "Developing a tailored strategy",
            ],
            icon: "mdi-lightbulb",
            iconColor: "amber",
            expanded: false,
          },
          {
            title: "Design",
            description: "Crafting a captivating design for both web and mobile experiences.",
            items: [
              "Creating wireframes and prototypes",
              "Choosing typography and color palettes",
              "Refining the interface and interactions",
            ],
            icon: "mdi-pencil-ruler",
            iconColor: "cyan",
            expanded: false,
          },
          {
            title: "Development & Testing",
            description: "Building and fine-tuning your website or app for a seamless experience.",
            items: [
              "Implementing functionality and features",
              "Thorough testing on all devices and platforms",
              "Finalizing the launch strategy",
            ],
            icon: "mdi-cog",
            iconColor: "green",
            expanded: false,
          },
        ],
        hoverStates: [false, false, false], // To manage hover states for each card
      };
    },
    methods: {
      toggleExpand(index) {
        this.$set(this.steps[index], "expanded", !this.steps[index].expanded);
      },
    },
  };
  </script>
  
  <style scoped>
  .custom-background {
    background-color: black;
    min-height: 57vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4rem 2rem;
  }
  
  .title {
    font-size: 3rem;
    color: #ffffff;
    margin-bottom: 50px;
    font-family: 'Montserrat', sans-serif;
    animation: fadeInDown 1s ease-out;
  }
  
  .animated-title {
    animation: fadeInDown 1s ease-out;
  }
  
  .custom-card {
    background: #ffffff;
    border-radius: 16px;
    padding: 30px;
    width: 100%; /* Make card take full width of its container */
    min-width: 250px; /* Set a max-width for larger screens */
    border: 2px solid #ddd;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    position: relative;
    text-align: center; /* Center all text within the card */
  }
  
  .custom-card.hovered {
    transform: translateY(-10px) scale(1.02);
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  }
  
  .icon {
    font-size: 2.5rem;
  }
  
  .description {
    font-size: 1rem;
    margin-bottom: 15px;
    color: #555;
  }
  
  ul {
    padding-left: 25px;
    list-style-type: disc;
    color: #333;
  }
  
  li {
    margin-bottom: 8px;
    font-size: 0.9rem;
    transition: color 0.3s ease;
  }
  
  li:hover {
    color: #555;
  }
  
  .mt-10 {
    margin-top: 6rem !important;
  }
  
  .pa-16 {
    padding: 6rem !important;
  }
  
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive Typography */
  @media (max-width: 960px) {
    .title {
      font-size: 2.5rem;
      margin-bottom: 30px;
    }
  }
  
  @media (max-width: 600px) {
    .title {
      font-size: 2rem;
      margin-bottom: 20px;
    }
  
    .custom-card {
      padding: 20px;
      max-width: 100%; /* Allow card to take full width on mobile */
    }
  
    .description {
      font-size: 0.95rem;
    }
  
    .icon {
      font-size: 2rem;
    }
  }
  </style>
  