<template>
  <div>
    <!-- Fixed Navigation Bar -->
    <v-row :class="headerClasses" no-gutters>
      <v-col cols="6" class="logo-section pa-0">
        <v-row>
        <img style="z-index:9;" src="@/assets/logo - Copy.png" alt="logo" class="logo" />
        <h3 class="mx-10 my-7" style="font-weight: 900; text-shadow: 0px 0px 0px #000;">CODE FIT FOR ROYALTY</h3>

      </v-row>
      </v-col>
      <v-col cols="6" class="nav-section">
        <!-- Desktop Navigation Links -->
        <nav class="desktop-nav">
          <ul>
            <li><router-link to="/">Home</router-link></li>
            <li><router-link to="/about">About Us</router-link></li>
            <li><router-link to="/contact">Contact Us</router-link></li>
            <li><router-link to="/packages">Packages</router-link></li> <!-- Added Packages Link -->
          </ul>
        </nav>
        <!-- Mobile Hamburger Menu -->
        <div class="mobile-nav">
          <v-btn icon @click="drawer = !drawer">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- Navigation Drawer for Mobile -->
    <v-navigation-drawer style="z-index:99;border-radius:40px 0px 0px 40px;" v-model="drawer" app temporary right>
      <!-- Close Button at the Top Right -->
      <v-btn icon @click="drawer = false" class="close-btn">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-row justify="center" align="center">
        <v-col cols="12" class="text-center">
          <v-list style="margin-top:60px" dense>
            <!-- Centered Logo Image -->
            <v-list-item class="my-10">
              <v-img
                src="@/assets/logo - Copy.png"
                alt="logo"
                class="logo mx-auto"
                max-width="170"
                height="100px"
              />
            </v-list-item>
            
            <!-- Centered Navigation Links -->
            <v-list-item @click="navigate('/')">
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigate('/about')">
              <v-list-item-title>About Us</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigate('/contact')">
              <v-list-item-title>Contact Us</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigate('/packages')"> <!-- Added Packages Link -->
              <v-list-item-title>Packages</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-navigation-drawer>
  </div>
</template>


<script>
export default {
  name: "NavBar",
  data() {
    return {
      isScrolled: false,
      drawer: false, // Controls the visibility of the mobile navigation drawer
    };
  },
  computed: {
    headerClasses() {
      let classes = ["header-section"];
      if (this.$route && this.$route.path === "/") {
        if (this.isScrolled) {
          classes.push("scrolled");
        }
      } else {
        classes.push("scrolled"); // Always apply 'scrolled' on other routes
      }
      return classes;
    },
  },
  methods: {
    handleScroll() {
      if (this.$route && this.$route.path === "/") {
        this.isScrolled = window.scrollY > 50; // Adjust the threshold as needed
      } else {
        this.isScrolled = true; // Always true on other routes
      }
    },
    navigate(path) {
      this.drawer = false; // Close the navigation drawer
      this.$router.push(path); // Navigate to the selected route
    },
  },
  mounted() {
    // Bind 'this' to the handleScroll method
    this.handleScroll = this.handleScroll.bind(this);

    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll(); // Initialize the state
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    $route() {
      this.handleScroll(); // Update on route change
    },
  },
};
</script>

<style scoped>
/* Close button styling */
.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
}

/* General Header Styles */
.header-section {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 60px;
  z-index: 90;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  background-color: transparent;
}

.header-section.scrolled {
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.logo {
  height: 80px;
}

.nav-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.desktop-nav {
  display: none;
}

.desktop-nav ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
}

.desktop-nav ul li {
  margin-left: 30px;
}
@media only screen and (max-width: 767px) {
    h3.mx-10.my-7 {
        display: none;
    }
}

.desktop-nav ul li a {
  text-decoration: none;
  color: #fcfcfc;
  font-size: 16px;
  font-weight: bold;
  transition: color 0.3s ease;
}

.desktop-nav ul li a:hover {
  color: #4b4b4b;
}

.header-section.scrolled .desktop-nav ul li a {
  color: #000;
}

.mobile-nav {
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  .desktop-nav {
    display: block;
  }
  .mobile-nav {
    display: none;
  }
}

.page-content {
  padding-top: 100px;
  padding-left: 20px;
  padding-right: 20px;
}

h1 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

p {
  font-size: 18px;
}
</style>
