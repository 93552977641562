import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Contact from '../views/Contact.vue'
import Packages from '../views/Package.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/packages',
    name: 'Packages',
    component: Packages
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // Keeps scroll position on back/forward navigation
    } else {
      return { x: 0, y: 0 }; // Scrolls to top on route change
    }
  }
})

export default router
