<template>
  <v-container fluid class="new-section">
    <v-row justify="center">
      <v-col cols="12" md="10">
        <div class="new-section-content">
          <!-- Optional: Add a heading if needed -->
          <!-- <h2>Technologies We Use</h2> -->
          <div class="logos-wrapper">
            <div class="logos">
              <div class="icon-wrapper" v-for="icon in icons" :key="icon">
                <v-icon :class="['icon', icon.size]">{{ icon.name }}</v-icon>
              </div>
            </div>
            <div class="fade fade-left"></div>
            <div class="fade fade-right"></div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      icons: [
        { name: 'mdi-language-javascript', size: 'large' },
        { name: 'mdi-language-python', size: 'large' },
        { name: 'mdi-language-java', size: 'large' },
        { name: 'mdi-language-csharp', size: 'large' },
        { name: 'mdi-language-ruby', size: 'large' },
        { name: 'mdi-language-php', size: 'large' },
        { name: 'mdi-language-typescript', size: 'large' },
        { name: 'mdi-language-go', size: 'large' },
        { name: 'mdi-language-swift', size: 'large' },
      ],
    };
  },
};
</script>

<style scoped>
.new-section {
  padding: 40px 0;
  background-color: #f0f0f0; /* Light grey background */
}

.new-section-content {
  text-align: center;
}

.new-section h2 {
  color: #333;
  font-size: 40px;
  margin-bottom: 40px;
}

/* Wrapper for gradient effect */
.logos-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

/* Logo scrolling container */
.logos {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  animation: scroll 30s ease-in-out infinite; /* Adjusted speed and added easing */
}

/* Gradient fades */
.fade {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10%;
  z-index: 1;
  pointer-events: none;
}

.fade-left {
  left: 0;
  background: linear-gradient(to right, #f0f0f0, transparent);
}

.fade-right {
  right: 0;
  background: linear-gradient(to left, #f0f0f0, transparent);
}

/* Individual icon container */
.icon-wrapper {
  display: inline-block;
  margin: 0 40px; /* Reduced spacing for better mobile fit */
}

.icon {
  font-size: 80px; /* Default icon size for desktop */
  color: #666;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .icon {
    font-size: 50px; /* Smaller icon size for mobile */
  }

  .icon-wrapper {
    margin: 0 20px; /* Reduced spacing for mobile */
  }

  .logos {
    animation: scroll 20s linear infinite; /* Adjust animation speed if necessary */
  }
}

@media (max-width: 400px) {
  .icon {
    font-size: 40px; /* Further reduce icon size for very small screens */
  }

  .icon-wrapper {
    margin: 0 10px; /* Further reduce spacing */
  }
}

/* Smoother scroll animation */
@keyframes scroll {
  0% {
    transform: translateX(50%); /* Start from center */
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
