<template>
  <div>
    <!-- Import NavBar component here -->

    <!-- Main Section -->
    <v-container fluid class="home-section" style="margin-top:-90px">
      <v-row no-gutters>
        <!-- Left Section (Text) -->
        <v-col
          cols="12"
          md="4"
          class="left-section"
          :class="{ 'text-black-mobile': isMobile }"
        >
          <div
            :class="{'mobile-margin-left': isMobile, 'desktop-margin-left': !isMobile}"
            class="profile-details"
          >
            <h4 class="intro-text">Welcome to</h4>

            <h1 class="name">King Code</h1>
            <p class="title">Where Innovation Meets Excellence</p>
            <p class="description">
              At King Code, we bring your digital vision to life with cutting-edge
              software development solutions. As a premier development company, we
              specialize in transforming ideas into robust, scalable, and user-friendly
              applications.
            </p>
            <v-btn
  class="contact-us-btn"
  rounded
  dark
  color="black"
  @click="$router.push('/contact')"
>

  Contact Us
</v-btn>

          </div>

          <!-- Social Media Icons (Left Sidebar) -->
          <div
            v-if="!isMobile"
            class="social-icons"
          >
            <v-btn icon class="social-btn">
              <v-icon>mdi-phone</v-icon>
            </v-btn>
            <v-btn icon class="social-btn">
              <v-icon>mdi-email</v-icon>
            </v-btn>
         
          </div>
        </v-col>

        <!-- Right Section (Profile Image and Background Image) -->
        <v-col
          cols="12"
          md="8"
          class="right-section"
          v-if="!isMobile"
        >
          <div class="profile-picture">
            <img src="@/assets/person.png" alt="profile" />
          </div>

          <!-- Vertical Welcome Text -->
          <div class="welcome-text">
            <h1>Welcome</h1>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <Brands />
    <About />
    <Work />
    <Offer />
    <Dream />
    <!-- <comments/> -->
    <!-- <contact /> -->
  </div>
</template>
<script>
import Brands from '@/components/Brands.vue';
import About from '@/components/About.vue';
import Offer from '@/components/Offer.vue';
import Work from '@/components/Work.vue';
import comments from '@/components/comments.vue';
import contact from '@/components/Contact.vue';
import Dream from '@/components/Dream.vue';

export default {
  name: "HomePage",
  components: {
    Brands,
    About,
    Offer,
    Work,
    comments,
    contact,
    Dream
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>
<style scoped>
/* Main container for the home section */
.home-section {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden; /* Prevents any overflow issues */
}

/* Header section with logo and navigation */
.header-section {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 60px;
  z-index: 10; /* Ensure it stays above the background */
}

.logo {
  height: 130px;
}

.nav-section ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
}

.nav-section ul li {
  margin-left: 30px;
}

.nav-section ul li a {
  text-decoration: none;
  color: #fcfcfc;
  font-size: 16px;
  font-weight: bold;
}

.nav-section ul li a:hover {
  color: #4b4b4b;
}

/* Left Section Styling */
.left-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Removed padding-left to handle via responsive classes */
  z-index: 2; /* Ensure it's above the background */
  margin-top: 20px; /* Move the content away from the top */
  animation: slideInFromLeft 1s ease-out forwards; /* Animation applied */
}

.profile-details {
  max-width: 450px;
  margin-top:200px;
  color: #333;
}

.intro-text {
  color: black;
  font-size: 24px;
  margin-bottom: 10px;
}

.name {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 10px;
}

.title {
  font-size: 22px;
  color: #6c757d;
}

.description {
  margin: 20px 0;
  color: #6c757d;
}

.design-link {
  font-weight: bold;
  color: #00af91;
  text-decoration: none;
}

.signature {
  margin-top: 20px;
}

.watch-video-btn {
  margin-top: 20px;
}

/* Social Media Icons (vertical left bar) */
.social-icons {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 10px;
  bottom:32%;
  background-color: #f1f2f3;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  padding: 15px 10px;
  width: 60px;
}

.social-btn {
  background-color: rgba(145, 145, 145, 0.2);
  border-radius: 50%;
  padding: 10px;
  margin-bottom: 20px;
}

.social-btn:last-child {
  margin-bottom: 0;
}

/* Right Section Styling */
.right-section {
  position: relative;
  background-image: url('@/assets/bg.png'); /* Replace with your image */
  background-size: cover; /* Ensure it covers the entire section */
  background-position: center center; /* Center the image */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 103vh;
  z-index: 1;
  margin-top:10px;
  animation: slideInFromRight 1s ease-out forwards; /* Animation applied */
}

/* Profile picture styling */
.profile-picture {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}

.profile-picture img {
  max-width: 70%;
  height: auto;
  margin-top: 180px;
  margin-right: 300px;
  border-radius: 5px;
  animation: fadeIn 2s ease-in-out forwards; /* Animation applied */
}

/* Welcome text aligned vertically */
.welcome-text {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(90deg); /* Rotating to face left */
  font-size: 100px;
  color: rgba(255, 255, 255, 0.1);
  z-index: 0;
  margin-right: -310px; /* Adjust spacing to align with the edge */
}

.welcome-text h1 {
  animation: fadeIn 3s ease-in-out forwards; /* Animation applied */
}

/* Entrance Animation Keyframes */
@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Responsive Margin Classes */
.desktop-margin-left {
  margin-left: 100px;
}

.mobile-margin-left {
  margin-left: 20px;
}

/* Mobile Specific Styles */
@media (max-width: 600px) {
  .left-section {
    /* Removed global padding, handled via profile-details */
    padding: 0; /* Ensure no extra padding */
    margin-top: 10px;
  }

  .profile-details {
    max-width: 100%;
    padding: 0 20px; /* Add horizontal padding for mobile */
  }

  .name {
    font-size: 32px;
  }

  .intro-text,
  .title,
  .description {
    color: black; /* Ensure text is black on mobile */
  }

  .watch-video-btn {
    margin-top: 10px;
  }

  .right-section {
    display: none; /* Hide the right section on mobile */
  }

  .social-icons {
    display: none; /* Hide social icons on mobile */
  }
}

/* Additional Class for Text Color on Mobile */
.text-black-mobile {
  color: black !important;
}
</style>
