<template>
  <v-container fluid class="pa-0">
    <!-- Banner Section -->
    <v-img
      src="https://images.unsplash.com/photo-1499750310107-5fef28a66643?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      class="about-us-image"
    >
      <v-overlay :value="true" class="black-overlay">
        <v-container fluid fill-height>
   <v-row align="center" justify="center" class="text-center content-wrapper">
  <v-col cols="12" data-aos="fade-up">
    <h1 style="margin-top:80px" class="white--text font-weight-bold" data-aos="zoom-in">
      Get In Touch

    </h1>
    <h2 class="white--text subtitle mt-3" data-aos="fade-up" data-aos-delay="200">
      Reach Out for Your Personalized Quote
    </h2>
  </v-col>
</v-row>

        </v-container>
      </v-overlay>
    </v-img>

    <!-- Contact Info and Form Section -->
    <v-container class="my-5">
      <v-row>
        <!-- Contact Information -->
        <v-col cols="12" md="6" data-aos="fade-right">
          <!-- <h2 class="contact-info-title">Contact Info:</h2>
          <p class="contact-description">
            To give give beginning divide, cattle. Give moving won't, there the abundantly she'd she'd brought air upon. Light hath subdue. Life days creature upon first heaven gathering dry.
          </p> -->
          <p class="contact-detail"><strong>Phone:</strong> 081 886 9597</p>
          <p class="contact-detail"><strong>Email:</strong> Admin@king-code.online</p>
        </v-col>

        <!-- Contact Form with EmailJS Integration -->
        <v-col cols="12" md="6" data-aos="fade-left">
          <v-card class="black-card">
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field 
                    v-model="name" 
                    label="Your Name" 
                    required 
                    filled dark rounded 
                    :rules="[v => !!v || 'Name is required']" 
                    aria-label="Your Name"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field 
                    v-model="email" 
                    label="Your Email" 
                    required 
                    filled dark rounded 
                    :rules="[v => /.+@.+\..+/.test(v) || 'Email must be valid']"
                    aria-label="Your Email"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field 
                    v-model="title" 
                    label="Your Title" 
                    aria-label="Your Title"
                    filled dark rounded 
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea 
                    v-model="message" 
                    label="Your Comment" 
                    rows="5" 
                    aria-label="Your Comment"
                    filled dark rounded 
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-btn 
                @click="sendEmail" 
                class="white--text my-5" 
                color="black" 
                outlined 
                dark
                :disabled="!valid"
                aria-label="Send Message"
              >
                Send Message
              </v-btn>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Footer Section -->
    <!-- <Footer /> -->
  </v-container>
</template>

<script>
import Footer from '@/components/Contact.vue';
import AOS from 'aos';
import 'aos/dist/aos.css';
import emailjs from 'emailjs-com';

export default {
  components: {
    Footer,
  },
  name: 'AboutUsImage',
  data() {
    return {
      name: '',
      email: '',
      title: '',
      message: '',
      valid: false,
    };
  },
  methods: {
    sendEmail() {
      emailjs.init("bdRucqs2Mb89bxTFm"); // Your EmailJS user ID

      const templateParams = {
        name: this.name,
        email: this.email,
        title: this.title,
        message: this.message,
      };

      emailjs.send('service_xndooqm', 'template_c9k2bc3', templateParams)
        .then(response => {
          console.log('Email sent successfully', response.status, response.text);
          this.resetForm(); // Optional: reset form on success
        })
        .catch(error => {
          console.error('Failed to send email', error);
        });
    },
    resetForm() {
      this.name = '';
      this.email = '';
      this.title = '';
      this.message = '';
      this.$refs.form.resetValidation();
    },
  },
  mounted() {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
    });
  },
};
</script>

<style scoped>
.about-us-image {
  height: 70vh;
  background-size: cover;
  background-position: center;
}

.black-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  height: 70vh;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-info-title {
  color: black;
  font-size: 2.2rem;
  margin-bottom: 10px;
}

.contact-description {
  color: grey;
  font-size: 1.2rem;
}

.contact-detail {
  color: black;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.black-card {
  background-color: #000;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.large-text-field {
  font-size: 1.2rem;
  color: white;
  background-color: #ffffff;
}

.large-text-fields {
  font-size: 1.2rem;
  color: white;
  background-color: #ffffff;
}

.v-btn {
  font-size: 1.5rem;
  padding: 12px 24px;
}

.v-card {
  border-radius: 16px;
  transition: all 0.3s ease-in-out;
}
</style>
