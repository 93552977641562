<template>
  <v-container class="affordable-services-section" fluid>
    <!-- Title Section -->
    <div class="title-container">
      <h5 class="subtitle">WHAT WE OFFER</h5>
      <h2 class="main-title">Affordable Services</h2>
      <p class="description">
        A Private Limited is the most popular type of partnership in Malta. The limited liability is, in fact, the only type of company allowed by Companies.
      </p>
    </div>

    <!-- Services Section with AOS Scroll Animation -->
    <v-row class="service-row" justify="center">
      <!-- Service Card 1 -->
      <v-col
        cols="12"
        sm="6"
        md="4"
        class="service-col"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <v-card class="service-card">
          <v-icon large class="service-icon" color="grey darken-1">mdi-tools</v-icon>
          <div class="service-content">
            <h3 class="service-title">Custom Software Development</h3>
            <p class="service-description">
              Tailored software solutions designed to meet your specific business needs, from web applications and desktop software to specialized tools.
            </p>
          </div>
        </v-card>
      </v-col>

      <!-- Service Card 2 -->
      <v-col
        cols="12"
        sm="6"
        md="4"
        class="service-col"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
      >
        <v-card class="service-card">
          <v-icon large class="service-icon" color="grey darken-1">mdi-cellphone</v-icon>
          <div class="service-content">
            <h3 class="service-title">Mobile App Development</h3>
            <p class="service-description">
              Development of cross-platform and native mobile applications for iOS and Android, with engaging interfaces and robust functionality.
            </p>
          </div>
        </v-card>
      </v-col>

      <!-- Service Card 3 -->
      <v-col
        cols="12"
        sm="6"
        md="4"
        class="service-col"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="400"
      >
        <v-card class="service-card">
          <v-icon large class="service-icon" color="grey darken-1">mdi-web</v-icon>
          <div class="service-content">
            <h3 class="service-title">Web Development</h3>
            <p class="service-description">
              Creation of high-quality, responsive websites and web applications that are visually appealing, user-friendly, and optimized for performance.
            </p>
          </div>
        </v-card>
      </v-col>

      <!-- Service Card 4 -->
      <v-col
        cols="12"
        sm="6"
        md="4"
        class="service-col"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="600"
      >
        <v-card class="service-card">
          <v-icon large class="service-icon" color="grey darken-1">mdi-cloud</v-icon>
          <div class="service-content">
            <h3 class="service-title">Cloud Solutions & Integration</h3>
            <p class="service-description">
              Design and implementation of scalable cloud-based applications, integrating existing systems with services like AWS, Azure, or Google Cloud.
            </p>
          </div>
        </v-card>
      </v-col>

      <!-- Service Card 5 -->
      <v-col
        cols="12"
        sm="6"
        md="4"
        class="service-col"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="800"
      >
        <v-card class="service-card">
          <v-icon large class="service-icon" color="grey darken-1">mdi-shield-check</v-icon>
          <div class="service-content">
            <h3 class="service-title">Software Testing & QA</h3>
            <p class="service-description">
              Comprehensive testing services to ensure software reliability, security, and optimal performance, with a focus on usability and functionality.
            </p>
          </div>
        </v-card>
      </v-col>

      <!-- Service Card 6 -->
      <v-col
        cols="12"
        sm="6"
        md="4"
        class="service-col"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="1000"
      >
        <v-card class="service-card">
          <v-icon large class="service-icon" color="grey darken-1">mdi-wrench</v-icon>
          <div class="service-content">
            <h3 class="service-title">Maintenance & Support</h3>
            <p class="service-description">
              Ongoing support to keep your software up-to-date, secure, and optimized, including updates, troubleshooting, and enhancements as needed.
            </p>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  mounted() {
    AOS.init({
      once: true, // Animation occurs only once while scrolling down
    });
  },
};
</script>

<style scoped>
.affordable-services-section {
  padding: 100px;
  background-color: #ffffff; /* White background */
}

@media (max-width: 600px) {
  .affordable-services-section {
    padding: 40px 20px; /* Reduced padding for mobile */
  }
}

/* Title Section */
.title-container {
  text-align: center;
  margin-bottom: 3rem;
}

.subtitle {
  font-size: 14px;
  color: #707070; /* Dark grey */
  letter-spacing: 1px;
  text-transform: uppercase;
}

@media (max-width: 600px) {
  .subtitle {
    font-size: 12px;
  }
}

.main-title {
  font-size: 36px;
  font-weight: 700;
  color: #000; /* Black */
  margin: 10px 0;
}

@media (max-width: 600px) {
  .main-title {
    font-size: 28px;
  }
}

.description {
  font-size: 16px;
  color: #606060; /* Medium grey */
  max-width: 700px;
  margin: 0 auto;
}

@media (max-width: 600px) {
  .description {
    font-size: 14px;
    max-width: 100%;
  }
}

/* Service Cards */
.service-row {
  margin-top: 2rem;
}

.service-col {
  padding: 20px;
  display: flex; /* Enable Flexbox */
}

.service-card {
  padding: 40px;
  background-color: #e0e0e0; /* Grey cards */
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05); /* Light shadow */
  transition: box-shadow 0.3s ease-in-out;
  flex: 1; /* Allow the card to grow and fill the column */
  display: flex; /* Enable Flexbox within the card */
  flex-direction: column; /* Stack children vertically */
}

@media (max-width: 600px) {
  .service-card {
    padding: 20px;
  }
}

.service-card:hover {
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.1); /* Darker shadow on hover */
}

/* Service Content */
.service-content {
  flex: 1; /* This section will expand to fill available space */
}

/* Service Icon */
.service-icon {
  font-size: 40px;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 50%;
}

@media (max-width: 600px) {
  .service-icon {
    font-size: 30px;
    padding: 10px;
    margin-bottom: 15px;
  }
}

/* Service Title */
.service-title {
  font-size: 20px;
  font-weight: 600;
  color: #000; /* Black for titles */
  margin-bottom: 15px;
}

@media (max-width: 600px) {
  .service-title {
    font-size: 18px;
  }
}

/* Service Description */
.service-description {
  font-size: 16px;
  color: #606060; /* Medium grey for text */
  line-height: 1.5;
  /* Removed max-width for consistency */
  margin: 0 auto;
}

@media (max-width: 600px) {
  .service-description {
    /* Ensure full width on mobile */
    font-size: 14px;
  }
}
</style>
