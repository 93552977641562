<template>
    <v-container class="testimonial-section" fluid>
      <v-row class="justify-center">
        <v-col cols="12" class="text-center">
          <h4 class="testimonial-title">TESTIMONIAL</h4>
          <h2 class="testimonial-heading">People talk about us</h2>
          <p class="testimonial-description">
            A Private Limited is the most popular type of partnership Malta. The
            limited liability is, in fact, the only type of company allowed by
            Companies.
          </p>
        </v-col>
      </v-row>
  
      <v-row justify="center" align="center" class="justify-center custom-carousel">
        <v-col cols="12" md="8">
          <div class="carousel-container">
            <v-btn icon class="carousel-control prev" @click="prevTestimonial">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
  
            <div
              v-for="(testimonial, index) in displayedTestimonials"
              :key="index"
              :class="{
                'carousel-item': true,
                'is-active': index === 1, // Second item is always active
                'is-prev': index === 0,
                'is-next': index === 2,
              }"
            >
              <div class="testimonial-card">
                <v-avatar height="100" width=100 style="font-size:30px;margin-top:-30px;" class="testimonial-avatar mb-12">
                  <v-img :src="testimonial.image" alt="testimonial avatar" />
                </v-avatar>
                <p class="testimonial-text">{{ testimonial.text }}</p>
                <h4 class="testimonial-name">{{ testimonial.name }}</h4>
                <p class="testimonial-title">{{ testimonial.title }}</p>
              </div>
            </div>
  
            <v-btn icon class="carousel-control next" @click="nextTestimonial">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    data() {
      return {
        currentIndex: 0,
        testimonials: [
          {
            name: "Janice C. Campbe",
            title: "Embedded analytics",
            text: "We use a customized application specifically designed a testing gnose to keep away for people.",
            image: "https://i.pravatar.cc/300?img=1",
          },
          {
            name: "John Doe",
            title: "Software Engineer",
            text: "We use a customized application specifically designed a testing gnose to keep away for people.",
            image: "https://i.pravatar.cc/300?img=2",
          },
          {
            name: "Jane Smith",
            title: "Data Scientist",
            text: "We use a customized application specifically designed a testing gnose to keep away for people.",
            image: "https://i.pravatar.cc/300?img=3",
          },
          {
            name: "Emily Turner",
            title: "Web Developer",
            text: "We use a customized application specifically designed a testing gnose to keep away for people.",
            image: "https://i.pravatar.cc/300?img=4",
          },
          {
            name: "Michael Scott",
            title: "Sales Manager",
            text: "We use a customized application specifically designed a testing gnose to keep away for people.",
            image: "https://i.pravatar.cc/300?img=5",
          },
        ],
      };
    },
    computed: {
      displayedTestimonials() {
        // Always slice 3 items and place the middle one as active
        return this.testimonials.slice(this.currentIndex, this.currentIndex + 3);
      },
    },
    methods: {
      prevTestimonial() {
        this.currentIndex =
          (this.currentIndex - 1 + this.testimonials.length) % this.testimonials.length;
      },
      nextTestimonial() {
        this.currentIndex = (this.currentIndex + 1) % this.testimonials.length;
      },
    },
  };
  </script>
  
  <style scoped>
  .testimonial-section {
    background-color: #f9f9f9;
    padding: 50px 0;
  }
  
  .testimonial-title {
    color: #999999;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .testimonial-heading {
    font-size: 2.5rem;
    color: #2a2a2a;
  }
  
  .testimonial-description {
    font-size: 1rem;
    color: #666666;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .custom-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
  }
  
  .carousel-container {
    display: flex;
    justify-content: center; /* Center items */
    align-items: center;
    position: relative;
    overflow: hidden;
    padding: 0 60px;
    height: 470px;
  }
  
  .carousel-item {
    margin: 0 20px; /* Adjust margin for spacing */
    width: 260px; /* Reduced width for better centering */
    transition: transform 0.5s ease, opacity 0.5s ease;
    opacity: 0.5;
  }
  
  .is-active {
    transform: scale(1.2); /* Increased scale for the active item */
    opacity: 1;
    z-index: 2;
    border-radius: 20px;
    outline: 2px solid #000000; /* Outline for active card */
    outline-offset: 5px; /* Adjust outline distance */
  }
  
  .is-prev,
  .is-next {
    opacity: 0.5;
    transform: scale(0.9); /* Smaller scale for the neighboring items */
  }
  
  .testimonial-card {
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    min-height: 220px;
    position: relative;
  }
  
  .testimonial-avatar {
    position: absolute;
    top: -60px; /* Adjusted for bigger avatar */
    left: 50%;
    transform: translateX(-50%);
    border: 5px solid white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    width: 220px; /* Increased avatar size */
    height: 220px; /* Increased avatar size */
    z-index: 1;
    overflow: hidden;
  }
  
  .testimonial-avatar img {
    width: 100px;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .testimonial-text {
    font-size: 1rem;
    color: #555555;
    margin-bottom: 10px;
    font-style: italic;
  }
  
  .testimonial-name {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .testimonial-title {
    font-size: 1rem;
    color: #999999;
  }
  
  .carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #999999;
    background-color: white;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    z-index: 3;
    width: 40px;
    height: 40px;
  }
  
  .prev {
    left: 0;
  }
  
  .next {
    right: 0;
  }
  </style>
  