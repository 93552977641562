<template>
  <v-container fluid class="about-me-section">
    <v-row no-gutters align="center">
      <!-- Profile Image Section -->
      <v-col
        cols="12"
        md="5"
        class="image-container"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        <v-img
          class="profile-image"
          :src="require('@/assets/person.png')"
          contain
          alt="Profile Image"
        />
      </v-col>

      <!-- Information and Custom Tabs Section -->
      <v-col
        cols="12"
        md="7"
        class="info-container"
        data-aos="fade-left"
        data-aos-duration="1000"
      >
        <h2 class="section-title my-3" data-aos="fade-up" data-aos-duration="1000">
          About King Code
        </h2>
        <p
          class="description"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-duration="1000"
        >
          King Code was established by passionate software engineers and tech enthusiasts dedicated to creating high-quality, customized software solutions...
        </p>

        <!-- Custom Tabs Navigation -->
        <div class="custom-tabs" data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000">
          <button
            :class="['tab-button', activeTab === 'skills' ? 'active' : '']"
            @click="activeTab = 'skills'"
          >
            Skills
          </button>
          <button
            :class="['tab-button', activeTab === 'experience' ? 'active' : '']"
            @click="activeTab = 'experience'"
          >
            Experience
          </button>
          <button
            :class="['tab-button', activeTab === 'education' ? 'active' : '']"
            @click="activeTab = 'education'"
          >
            Education
          </button>
        </div>

        <!-- Tabs Content -->
        <div class="tab-content">
          <!-- Skills Content -->
          <div
            v-if="activeTab === 'skills'"
            class="skills-container"
            data-aos="fade-up"
            data-aos-delay="600"
            data-aos-duration="1000"
          >
            <div class="skill" v-for="(skill, index) in skills" :key="index">
              <span class="skill-title">{{ skill.title }}</span>
              <v-progress-linear
                :value="skill.value"
                :color="skill.color"
                class="progress-bar"
                height="10"
                background-color="grey lighten-3" 
              ></v-progress-linear>
              <span class="percentage">{{ skill.value }}%</span>
            </div>
          </div>

          <!-- Experience Content -->
          <div
            v-if="activeTab === 'experience'"
            class="experience-container"
            data-aos="fade-up"
            data-aos-delay="600"
            data-aos-duration="1000"
          >
            <v-timeline class="custom-timeline">
              <v-timeline-item
                v-for="(job, index) in experience"
                :key="index"
                :color="job.color"
                :icon="job.icon"
              >
                <div class="timeline-content">
                  <h3 class="card-title">{{ job.position }} at {{ job.company }}</h3>
                  <h4 class="card-subtitle">{{ job.duration }}</h4>
                  <ul class="card-text">
                    <li v-for="(responsibility, idx) in job.responsibilities" :key="idx">
                      {{ responsibility }}
                    </li>
                  </ul>
                </div>
              </v-timeline-item>
            </v-timeline>
          </div>

          <!-- Education Content -->
          <div
            v-if="activeTab === 'education'"
            class="education-container"
            data-aos="fade-up"
            data-aos-delay="600"
            data-aos-duration="1000"
          >
            <v-timeline class="custom-timeline">
              <v-timeline-item
                v-for="(edu, index) in education"
                :key="index"
                :color="edu.color"
                :icon="edu.icon"
              >
                <div class="timeline-content">
                  <h3 class="card-title">{{ edu.degree }} in {{ edu.field }}</h3>
                  <h4 class="card-subtitle">{{ edu.institution }} | {{ edu.year }}</h4>
                  <p class="card-text">{{ edu.description }}</p>
                </div>
              </v-timeline-item>
            </v-timeline>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';
import {
  VContainer,
  VRow,
  VCol,
  VImg,
  VProgressLinear,
  VTimeline,
  VTimelineItem,
} from 'vuetify/lib';

export default {
  name: 'AboutMeSection',
  components: {
    VContainer,
    VRow,
    VCol,
    VImg,
    VProgressLinear,
    VTimeline,
    VTimelineItem,
  },
  data() {
    return {
      activeTab: 'skills',
      skills: [
        { title: 'Development', value: 65, color: 'blue' },
        { title: 'Design', value: 95, color: 'green' },
        { title: 'Branding', value: 80, color: 'purple' },
      ],
      experience: [
        {
          position: 'Senior Software Engineer',
          company: 'Tech Innovators Inc.',
          duration: 'Jan 2020 - Present',
          responsibilities: [
            'Lead a team of 10 developers in building scalable web applications.',
            'Architect and implement RESTful APIs and microservices.',
          ],
          color: 'blue',
          icon: 'mdi-briefcase',
        },
        {
          position: 'Frontend Developer',
          company: 'Creative Solutions LLC',
          duration: 'Jun 2017 - Dec 2019',
          responsibilities: [
            'Developed responsive user interfaces using Vue.js and React.',
            'Implemented state management with Vuex and Redux.',
          ],
          color: 'green',
          icon: 'mdi-code-tags',
        },
      ],
      education: [
        {
          degree: 'Bachelor of Science',
          field: 'Computer Science',
          institution: 'State University',
          year: '2013 - 2017',
          description: 'Graduated with Honors, specializing in software development.',
          color: 'purple',
          icon: 'mdi-school',
        },
        {
          degree: 'Master of Science',
          field: 'Software Engineering',
          institution: 'Tech Institute',
          year: '2018 - 2020',
          description: 'Focused on cloud computing and scalable system design.',
          color: 'orange',
          icon: 'mdi-school',
        },
      ],
    };
  },
  mounted() {
    AOS.init();
  },
};
</script>

<style scoped>
.about-me-section {
  padding: 3rem;
  background-color: #e0e0e0;
  border-radius: 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.image-container {
  text-align: center;
}

.profile-image {
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background-color: #1a1a1a;
  margin: 0 auto;
}

.info-container {
  padding-left: 40px;
  text-align: left;
}

.section-title {
  font-size: 34px;
  font-weight: bold;
  color: #1a1a1a;
  margin-bottom: 16px;
}

.description {
  font-size: 18px;
  font-weight: 300;
  color: #4d4d4d;
  line-height: 1.8;
  margin-bottom: 24px;
  max-width: 900px;
}

.custom-tabs {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  font-size: 16px;
  font-weight: bold;
  color: #1a1a1a;
  cursor: pointer;
  transition: border-bottom 0.3s, color 0.3s;
}

.tab-button:hover {
  color: #757575;
}

.tab-button.active {
  border-bottom: 2px solid #1a1a1a;
  color: #1a1a1a;
}

.tab-content {
  background-color: transparent;
  padding: 0;
}

.skills-container {
  background-color: transparent;
  padding: 0;
}

.skill {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.skill-title {
  font-size: 18px;
  font-weight: 500;
  color: #1a1a1a;
  flex: 1;
}

.progress-bar {
  flex: 2;
  margin: 0 12px;
  border-radius: 4px;
}

.percentage {
  font-size: 16px;
  font-weight: bold;
  color: #1a1a1a;
  flex: 0.5;
  text-align: right;
}

.custom-timeline .v-timeline-item::before {
  display: none;
}

.timeline-content {
  background-color: transparent;
  padding: 0;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  color: #1a1a1a;
  margin-bottom: 4px;
}

.card-subtitle {
  font-size: 14px;
  color: #757575;
  margin-bottom: 8px;
}

.card-text {
  font-size: 16px;
  color: #4d4d4d;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

/* Responsive Styles */
@media (max-width: 960px) {
  .about-me-section {
    padding: 2rem;
  }

  .profile-image {
    width: 400px;
    height: 400px;
  }

  .section-title {
    font-size: 28px;
  }

  .description {
    font-size: 16px;
    max-width: 100%;
  }

  .info-container {
    padding-left: 20px;
  }

  .skill-title {
    font-size: 16px;
  }

  .percentage {
    font-size: 14px;
  }

  .v-timeline {
    padding-left: 15px;
  }
}

@media (max-width: 600px) {
  .about-me-section {
    padding: 1.5rem;
  }

  .profile-image {
    width: 250px;
    height: 250px;
  }

  .section-title {
    font-size: 24px;
    text-align: center;
  }

  .description {
    font-size: 14px;
    text-align: center;
    margin: 0 auto 24px auto;
  }

  .skill-title {
    font-size: 16px;
  }

  .progress-bar {
    margin: 0 8px;
    width: 100%;
    margin-bottom: 8px;
  }

  .percentage {
    font-size: 14px;
    text-align: center;
  }

  .info-container {
    padding-left: 10px;
    text-align: center;
  }

  .custom-tabs {
    justify-content: center;
  }

  .skill {
    flex-direction: column;
    align-items: center;
  }
}
</style>
