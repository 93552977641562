<template>
  <v-container fluid class="background-container ">
    <!-- Package Cards Section -->
     <div class="pa-4 pa-md-12">
    <v-row
      justify="center"
      align="center"
      class="mx-auto"
      :class="{'pa-2 pa-sm-4': true}"
      no-gutters
    >
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        v-for="(pkg, index) in packages"
        :key="index"
        class="d-flex my-4"
        data-aos="fade-up" 
        :data-aos-delay="index * 100"
      >
        <v-card
          :id="'package-' + index"
          class="package-card"
          :class="[pkg.class, { 'best-value': pkg.isBestValue }]"
          outlined
        >
          <!-- Best Value Ribbon -->
          <div v-if="pkg.isBestValue" class="ribbon">
            Best Value
          </div>

          <div class="package-header text-center">{{ pkg.name }}</div>
          <!-- Display Price -->
        
          <v-list dense class="feature-list mx-10">
            <v-list-item v-for="(feature, i) in pkg.features" :key="i">
              <v-list-item-content>
                <v-list-item-title>
                  {{ feature.name }}:
                  <span :class="{'text-muted': feature.value === 'N/A'}">
                    {{ feature.value === 'N/A' ? 'N/A' : (feature.value || 'Optional') }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-btn
            dark
            :class="['shop-button', pkg.buttonClass]"
            depressed
            @click="redirectToContact"
          >
            Contact Us
          </v-btn>
          <!-- Upward Arrow Indicator with Tooltip -->
          <div v-if="pkg.isBestValue" class="package-footer">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="white"
                  size="36"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-arrow-up-bold-circle
                </v-icon>
              </template>
              <span>Upgrade for More Value</span>
            </v-tooltip>
          </div>
        </v-card>
      </v-col>
    </v-row>
    </div>
    <v-row class="mt-8">
      <h1 class="benefits-title my-10" style="color:white" data-aos="fade-in">Benefits</h1>
    </v-row>
    <div style="position: relative; width: 90%; ;">
  <!-- Overlay -->
  <div
    style="
      position: absolute;
      top: 0;
      left: 6%;
      width: 90%;
      height: 80%;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 30px;
      z-index: 1;
    "
  ></div>

  <!-- Image -->
  <v-img
    width="90%"
    style="border-radius: 30px; position: relative; z-index: 2;left:6%;margin-top:-10%"
    src="../assets/graph.png"
    alt="Background Image"
  ></v-img>
</div>


    </div>
    
  </v-container>
</template>
<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  data() {
    return {
      packages: [
        {
          name: "Starter Package",
          class: "starter-package",
          buttonClass: "starter-button",
          isBestValue: false,
          price: "$99",
          icon: "mdi-clock",
          shortDescription:
            "Ideal for personal projects and small businesses looking to establish an online presence.",
          projectKits: [
            "Personal Blogs",
            "Small Business Websites",
            "Portfolio Sites",
            "Landing Pages",
          ],
          features: [
            { name: "Pages", value: "1-3 Pages" },
            { name: "Additional Pages", value: "N/A" },
            { name: "Design", value: "Basic Design" },
            { name: "Domain", value: "Restricted" },
            { name: "Maintenance", value: "1 hour/month" },
            { name: "E-commerce", value: "N/A" },
            { name: "Contact Page", value: "Included" },
            { name: "Response Time", value: "5-7 days" },
            { name: "Basic SEO", value: "Included" },
            { name: "Newsletter Integration", value: "N/A" },
            { name: "Reporting/Analytics", value: "N/A" },
          ],
          valueScore: 1,
        },
        {
          name: "Standard Package",
          class: "standard-package",
          buttonClass: "standard-button",
          isBestValue: false,
          price: "$199",
          icon: "mdi-chart-bar",
          shortDescription:
            "Perfect for growing businesses requiring more features and better performance.",
          projectKits: [
            "Corporate Websites",
            "E-commerce Stores",
            "Medium-sized Blogs",
            "Service-oriented Websites",
          ],
          features: [
            { name: "Pages", value: "Up to 5 Pages" },
            { name: "Additional Pages", value: "N/A" },
            { name: "Design", value: "Basic Design" },
            { name: "Domain", value: "Restricted" },
            { name: "Maintenance", value: "3 hours/month" },
            { name: "E-commerce", value: "N/A" },
            { name: "Contact Page", value: "Included" },
            { name: "Response Time", value: "4-5 days" },
            { name: "Basic SEO", value: "Included" },
            { name: "Newsletter Integration", value: "Optional" },
            { name: "Reporting/Analytics", value: "N/A" },
          ],
          valueScore: 2,
        },
        {
          name: "Advanced Package",
          class: "advanced-package",
          buttonClass: "advanced-button",
          isBestValue: true,
          price: "$299",
          icon: "mdi-chart-line",
          shortDescription:
            "Designed for established businesses aiming for growth and enhanced user engagement.",
          projectKits: [
            "Large Corporate Websites",
            "Advanced E-commerce Platforms",
            "Online Communities",
            "Educational Platforms",
          ],
          features: [
            { name: "Pages", value: "Up to 10 Pages" },
            { name: "Additional Pages", value: "Optional" },
            { name: "Design", value: "Advanced Design" },
            { name: "Domain", value: "Not Restricted" },
            { name: "Maintenance", value: "5 hours/month" },
            { name: "E-commerce", value: "Optional" },
            { name: "Contact Page", value: "Included" },
            { name: "Response Time", value: "3-4 days" },
            { name: "Basic SEO", value: "Included" },
            { name: "Newsletter Integration", value: "Included" },
            { name: "Reporting/Analytics", value: "Optional" },
          ],
          valueScore: 4,
        },
        {
          name: "Premium Package",
          class: "premium-package",
          buttonClass: "premium-button",
          isBestValue: false,
          price: "$399",
          icon: "mdi-cash-check",
          shortDescription:
            "Tailored for enterprises requiring custom solutions and high-traffic handling.",
          projectKits: [
            "Enterprise Solutions",
            "Custom Software Platforms",
            "High-Traffic Websites",
            "International E-commerce Sites",
          ],
          features: [
            { name: "Pages", value: "Up to 20 Pages" },
            { name: "Additional Pages", value: "Optional" },
            { name: "Design", value: "Premium Design" },
            { name: "Domain", value: "Not Restricted" },
            { name: "Maintenance", value: "7 hours/month" },
            { name: "E-commerce", value: "Optional" },
            { name: "Contact Page", value: "Included" },
            { name: "Response Time", value: "Within 48 hours" },
            { name: "Basic SEO", value: "Included" },
            { name: "Newsletter Integration", value: "Included" },
            { name: "Reporting/Analytics", value: "Included" },
          ],
          valueScore: 5,
        },
      ],
      activePackage: null,
    };
  },
  methods: {
    redirectToContact() {
      this.$router.push("/contact");
    },
    scrollToPackage(index) {
      const target = this.$el.querySelector(`#package-${index}`);
      if (target) {
        target.scrollIntoView({ behavior: "smooth", block: "start" });
        this.activePackage = index;
      }
    },
    handleScroll() {
      const packageElements = this.$el.querySelectorAll(".package-card");
      const scrollPosition = window.scrollY + 150; // Adjust as needed

      packageElements.forEach((el, index) => {
        const offsetTop = el.offsetTop;
        const offsetHeight = el.offsetHeight;
        if (
          scrollPosition >= offsetTop &&
          scrollPosition < offsetTop + offsetHeight
        ) {
          this.activePackage = index;
        }
      });
    },
    getTimelineColor(pkgClass) {
      // This method can be updated or removed if colors are managed via inline styles
      switch (pkgClass) {
        case "starter-package":
          return "orange";
        case "standard-package":
          return "red";
        case "advanced-package":
          return "green";
        case "premium-package":
          return "blue";
        default:
          return "grey";
      }
    },
    refreshAOS() {
      this.$nextTick(() => {
        AOS.refresh();
      });
    },
  },
  watch: {
    packages() {
      this.refreshAOS();
    },
  },
  mounted() {
    AOS.init({
      duration: 800, // Animation duration in milliseconds
      easing: 'ease-in-out', // Easing function
      once: true, // Whether animation should happen only once
      mirror: false, // Whether elements should animate out while scrolling past them
    });
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() { // Use beforeUnmount() if you're using Vue 3
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style scoped>
.background-container {
  position: relative;
  margin-top: 50px;
  background-image: url('https://images.pexels.com/photos/221537/pexels-photo-221537.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  box-sizing: border-box;
}

.background-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
}

.package-card {
  position: relative;
  z-index: 1;
  border-radius: 16px;
  padding: 20px 15px;
  margin: 20px;
  width: 100%;
  max-width: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  min-height: 520px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
  box-sizing: border-box;
}

/* Best Value Styles */
.package-card.best-value {
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  border: 2px solid rgb(55, 145, 103);
}

.ribbon {
  position: absolute;
  top: 20px;
  left: -40px;
  width: 140px;
  text-align: center;
  background-color:rgb(55, 145, 103);
  color: white;
  padding: 5px 0;
  transform: rotate(-45deg);
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.package-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.package-price {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.feature-list {
  flex-grow: 1;
  max-width: 230px;
  margin-bottom: 20px;
}

.starter-package {
  border-top: 8px solid #FFA500; /* Orange */
}

.standard-package {
  border-top: 8px solid #FF6347; /* Tomato */
}

.advanced-package {
  border-top: 8px solid #BA55D3; /* Medium Orchid */
}

.premium-package {
  border-top: 8px solid #1E90FF; /* Dodger Blue */
}

.shop-button {
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  margin-top: 20px;
  background-color: #007bff;
  transition: background-color 0.3s ease;
}

.shop-button:hover {
  background-color: #0056b3;
}

.package-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.package-footer v-icon {
  transition: transform 0.3s ease;
}

.package-footer v-icon:hover {
  transform: translateY(-5px);
  cursor: pointer;
}

/* Enhanced Vuetify Timeline Styles */
.timeline-section {
  width: 100%;
  margin-top: 50px;
  position: relative;
  padding: 40px 0;
  box-sizing: border-box;
}

.timeline-container {
  position: relative;
  width: 90%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

/* Gradient line for the timeline */
.v-timeline {
  border-image-slice: 1;
  padding-left: 20px;
  position: relative;
}

/* Styling for timeline items */
.v-timeline-item {
  position: relative;
  margin-bottom: 40px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.v-timeline-item--active {
  transform: scale(1.05);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

.timeline-opposite {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(45deg, #f39c12, #2980b9);
  padding: 8px 12px;
  border-radius: 4px;
}

/* Value Score (Star Rating) Styles */
.value-score {
  margin-top: 10px;
}

.value-score v-icon {
  font-size: 20px;
}

/* Remove hover effects on v-card within timeline */
.v-timeline-item__content .v-card {
  cursor: pointer;
}

.v-timeline-item__content .v-card:hover {
  background-color: transparent; /* Remove background change on hover */
}

/* Package Name Styles */
.package-name {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
  transition: color 0.3s ease;
}

.package-name.starter-package {
  color: #FFA500; /* Orange */
}

.package-name.standard-package {
  color: #FF6347; /* Tomato */
}

.package-name.advanced-package {
  color: #BA55D3; /* Medium Orchid */
}

.package-name.premium-package {
  color: #1E90FF; /* Dodger Blue */
}

.info-icon {
  cursor: pointer;
}

.project-kits-content {
  text-align: left;
}

.project-kits-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.project-kits-content ul li {
  margin-bottom: 6px;
  font-size: 14px;
  color: #2c3e50;
}

.text-muted {
  color: #aaa;
}

.text-success {
  color: green;
}

.text-danger {
  color: red;
}

/* Hover Effects for Interactivity on Timeline Items */
.timeline-event:hover .package-name,
.timeline-event:focus .package-name {
  text-decoration: underline;
}

.timeline-event:hover .v-timeline__item-icon,
.timeline-event:focus .v-timeline__item-icon {
  transform: scale(1.2);
}

/* Responsive Font Sizes and Layout Adjustments */
@media (max-width: 960px) {
  .package-card {
    max-width: 100%;
  }
}

@media (max-width: 600px) {
  .background-container {
    padding: 10px;
  }

  .package-card {
    padding: 15px 10px;
    min-height: auto;
  }

  .package-header {
    font-size: 20px;
  }

  .package-price {
    font-size: 24px;
  }

  .feature-list {
    max-width: 100%;
    padding: 0 10px;
  }

  .shop-button {
    padding: 10px;
    font-size: 14px;
  }

  .benefits-title {
    font-size: 24px;
    text-align: center;
  }

  .timeline-section {
    padding: 20px 0;
  }

  .timeline-opposite {
    font-size: 14px;
    padding: 6px 10px;
  }

  .value-score v-icon {
    font-size: 18px;
  }
}
</style>
