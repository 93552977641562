<template>
  <v-container class="recent-project-section" fluid>
    <!-- Title Section -->
    <v-row justify="center" data-aos="fade-up">
      <h2 class="section-title pa-4">OUR PROJECTS</h2>
    </v-row>

    <!-- Project Cards Slider using v-carousel -->
    <v-carousel
      v-if="groupedProjects.length"
      v-model="currentSlide"
      :hide-delimiters="true"

      show-arrows-on-hover
      cycle
      class="project-slider my-12"
      data-aos="fade-up"
    >
      <!-- Carousel Slides -->
      <v-carousel-item
        v-for="(group, groupIndex) in groupedProjects"
        :key="groupIndex"
        data-aos="zoom-in"
      >
        <v-row class="project-cards" no-gutters justify="center">
          <!-- Project Card -->
          <v-col
            v-for="(project, index) in group"
            :key="index"
            cols="12"
            sm="6"
            md="3"
            class="project-card-col"
          >
            <v-card class="project-card ml-6">
              <v-img
                class="project-image"
                :height="isMobile ? '200px' : '270px'"
                :src="project.image"
                :alt="project.title"
                lazy
              />
              <div class="project-info" @click="toggleDescription(project)">
                <div class="title-category">
                  <h3 class="project-title">{{ project.title }}</h3>
                  <span class="project-category">| {{ project.category }}</span>
                </div>
                <!-- Expand Arrow Icon -->
                <v-icon dark :class="{'rotate-icon': project.showDescription}">
                  mdi-chevron-down
                </v-icon>
              </div>
              <!-- Expandable Description -->
              <v-expand-transition>
                <div v-if="project.showDescription" class="project-description">
                  <p>{{ project.description }}</p>
                </div>
              </v-expand-transition>
            </v-card>
          </v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>
  </v-container>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  data() {
    return {
      projects: [
      {
  title: 'Unlocked',
  category: 'Website Application',
  image: require('@/assets/unlocked.png'),
  description: 'A streaming website offering access to a wide range of shows with a secure user experience, including authentication and personalized management.',
  showDescription: false,
},
{
  title: 'Eyemanager',
  category: 'Website Application',
  image: require('@/assets/eye.png'),
  description: 'A robust data management system designed to add users, organize information, and streamline data handling for efficient user management.',
  showDescription: false,
}
,
{
  title: 'Ever-After',
  category: 'Application',
  image: require('@/assets/everafter.png'),
  description: 'A relationship app designed for couples to celebrate and manage shared milestones, memories, and their unique journey together.',
  showDescription: false,
}
,
{
  title: 'Custom Internal platform',
  category: 'Application',
  image: require('@/assets/nexus.png'),
  description: 'A comprehensive management application for engineering services, streamlining service tracking, orders, and operational efficiency.',
  showDescription: false,
}
,
{
  title: 'Baby Shower',
  category: 'Application',
  image: require('@/assets/baby.png'),
  description: 'A helpful checklist app for organizing baby showers, allowing users to set up item lists to ensure guests bring everything needed for the celebration.',
  showDescription: false,
}
,
{
  title: 'Streaming platform',
  category: 'Website Application',
  image: require('@/assets/anime.png'),
  description: 'A dedicated anime streaming platform, providing fans access to a vast selection of series and genres, all in one place.',
  showDescription: false,
}

      ],
      currentSlide: 0,
      isMobile: false,
    };
  },
  computed: {
    groupedProjects() {
      const itemsPerSlide = this.isMobile ? 1 : 3;
      const grouped = [];
      for (let i = 0; i < this.projects.length; i += itemsPerSlide) {
        grouped.push(this.projects.slice(i, i + itemsPerSlide));
      }
      return grouped;
    },
  },
  mounted() {
    AOS.init({ duration: 1200 });
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth < 600;
    },
    toggleDescription(project) {
      project.showDescription = !project.showDescription;
    },
  },
};
</script>

<style scoped>
.recent-project-section {
  background-color: #121212;
  padding: 3rem 1.5rem;
  color: white;
  height:69vh;
  position: relative;
}

.section-title {
  font-size: 36px;
  font-weight: 700;
  margin-top: 10px;
  color: white;
}

.project-slider {
  max-width: 90vw;
  margin: 0 auto;
}

.project-cards {
  display: flex;
}

.project-card-col {
  padding: 10px;
  box-sizing: border-box;
}

.project-card {
  border-radius: 12px;
  overflow: hidden;
  background-color: #1f1f1f;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease-in-out;
  color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
}

.project-card:hover {
  transform: scale(1.05);
}

.project-image {
  object-fit: cover;
}

.project-info {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

.title-category {
  display: flex;
  align-items: center;
}

.project-title {
  font-size: 18px;
  font-weight: bold;
  color: white;
  margin: 0;
}

.project-category {
  font-size: 14px;
  color: grey;
  margin-left: 0.5rem;
}

.project-description {
  padding: 1rem;
  color: grey;
  font-size: 14px;
}

/* Rotate icon when description is expanded */
.rotate-icon {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .project-card-col {
    padding: 5px;
  }

  .project-title {
    font-size: 16px;
  }

  .project-category {
    font-size: 12px;
  }

  .project-image {
    height: 200px;
  }
}
</style>
